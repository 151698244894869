import axios from 'axios'
import qs from 'qs'
import router from '@/router/index'
import {
    ElMessage
} from 'element-plus'
import useUserStore from '@/store/modules/user'

const toLogin = () => {
    useUserStore().logout().then(() => {
        router.push({
            name: 'login',
            query: {
                redirect: router.currentRoute.value.path !== '/login' ? router.currentRoute.value.fullPath : undefined
            }
        })
    })
}

const api = axios.create({
    baseURL: import.meta.env.DEV &&
        import.meta.env.VITE_OPEN_PROXY === 'true' ?
        '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
    // 主要是添加这一行
    withCredentials: true,
    timeout: 15000,
    responseType: 'json'
})

api.interceptors.request.use(
    request => {
        const userStore = useUserStore()
        /**
         * 全局拦截请求发送前提交的参数
         * 以下代码为示例，在请求头里带上 token 信息
         */
        if (userStore.isLogin) {
            request.headers['token'] = userStore.token
        }
        // 是否将 POST 请求参数进行字符串化处理
        if (request.method === 'post' && !request.url.includes('uploadAvatar')) {
            request.data = qs.stringify(request.data, {
                arrayFormat: 'brackets'
            }, {
                allowDots: true
            })
        }
        // 统一增加Authorization请求头, skipToken 跳过增加token
        const token = userStore.token;
        // console.log('request.headers')
        if (token && request.headers && request.headers.skipToken !== true) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }

        // 统一增加TENANT-ID请求头
        const tenantId = userStore.tenantId;
        if (tenantId) {
            request.headers['TENANT-ID'] = tenantId;
        }
        return request
    }
)

api.interceptors.response.use(
    response => {
        /**
         * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
         * 假设返回数据格式为：{ status: 1, error: '', data: '' }
         * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
         * 请求出错时 error 会返回错误信息
         */
        // console.log(response)
        if (Boolean(response.data) && response.data.hasOwnProperty('access_token') !== -1 || response.data.hasOwnProperty('refresh_token') !== -1) {
            return Promise.resolve(response.data)
        }
        // if (response) {
        //     return Promise.resolve(response.data)
        // }
        console.log('====base response====', response)
        if (response.data.code === 200 ||
            response.data.code === 10201 ||
            response.data.code === 10202 ||
            response.data.code === 14000 ||
            response.data.code === 401 ||
            response.data.code.toString().startsWith('102') ||
            response.data.code === 400) {
            if (response.data.msg === '') {
                // 请求成功并且没有报错
                return Promise.resolve(response.data)
            } else {
                // 消息提示的处理
                // ElMessage.error(options)
                return Promise.resolve(response.data)
            }
        } else {
            // toLogin()
            return Promise.reject(response.data)
        }
    },
    error => {
        const userStore = useUserStore()
        let message = error.message
        if (message == 'Network Error') {
            message = '我们正在全力抢修中'
        } else if (message.includes('timeout')) {
            message = '服务器暂无相应'
        } else if (message.includes('Request failed with status code')) {
            // message = '接口' + message.substr(message.length - 3) + '异常'
            message = '小哥哥正在修复中...'
        }
        if (error.request.status === 401) {
            return Promise.reject(error)
        }
        if (error.request.status === 424) {
            return Promise.reject(error)
        }
        ElMessage({
            message,
            type: 'warning'
        })
        return Promise.reject(error)
    }
)

export default api
